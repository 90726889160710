<!--  -->
<template>
  <div id="Home">
    <!-- 开始考试弹框 -->
    <el-dialog
      title="请选择做题模式"
      :visible.sync="centerDialogVisible"
      :width="rowObj.pattern == '自选模式' ? '576px' : '460px'"
      center
      @before-close="centerDialogVisible = false"
    >
      <div class="testType">
        <div
          @click="praticeType(1)"
          v-if="rowObj.pattern == '自选模式' || rowObj.pattern == '练习模式'"
          v-show="rowObj.id != 275"
        >
          <img src="@/assets/image/index2.png" alt="" />
          <p>练习模式</p>
          <p>解题思路随时查看</p>
        </div>
        <div
          @click="testType(2)"
          v-if="rowObj.pattern == '自选模式' || rowObj.pattern == '考试模式'"
        >
          <img src="@/assets/image/index3.png" alt="" />
          <p>考试模式</p>
          <p>须交卷方可显示答案</p>
        </div>
      </div> </el-dialog
    ><el-dialog
      title="输入密码"
      :visible.sync="passwordDialogVisible"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="home-banner">
      <div class="banner-inner">
        <div class="banner-title">西知题库 · 考试必备</div>
        <div class="banner-context">
          西语人最适合的答题系统，一站式学习+自测
        </div>
      </div>
    </div>
    <div class="home-content">
      <div class="home_left">
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
          <div class="list-cut">
            <div class="total_num">共（{{ total }}）份试卷</div>
            <div @click="clickSort">综合排序</div>
            <div class="hot" @click="clickActive">
              最热<i
                :class="active && !cur ? 'active' : ''"
                class="iconfont icon-xiangshang"
              ></i
              ><i
                :class="active && cur ? 'active' : ''"
                class="iconfont icon-xiangxia"
              ></i>
            </div>
          </div>
          <el-tab-pane
            :label="item.name"
            :name="item.name"
            v-for="(item, index) in allList"
            :key="index"
          >
            <el-table :data="tableData" stripe style="width: 100%">
              <el-table-column prop="name" label="试卷名称">
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :href="`#/Index/Testinfo?id=${scope.row.id}`"
                    target="_blank"
                    >{{ scope.row.name }}</el-link
                  >
                </template>
              </el-table-column>
              <el-table-column prop="difficultys" label="难度">
                <template slot-scope="scope">
                  <el-rate
                    v-model="scope.row.difficultys"
                    :colors="colors"
                    disabled
                    :size="40"
                  >
                  </el-rate>
                </template>
              </el-table-column>
              <el-table-column prop="questions_count" label="题数" width="150">
              </el-table-column>
              <el-table-column prop="grade_sum" label="总分" width="150">
              </el-table-column>
              <el-table-column
                prop="records_count"
                label="答题人次"
                width="150"
              >
              </el-table-column>

              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    @click="startTest(scope.row)"
                    type="primary"
                    size="small"
                    >去做题</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageSize.page"
              :page-sizes="[10, 15, 20]"
              :page-size="pageSize.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </el-tab-pane>
        </el-tabs> -->
        <div class="list-title">
          <div class="list_title_left">试卷分类</div>
          <ul class="list_title_right">
            <li
              :class="item.active ? 'active' : ''"
              v-for="(item, index) in allList"
              :key="index"
              @click="tabClick(index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="list-cut">
          <div class="total_num">共（{{ total }}）份试卷</div>
          <div @click="clickSort">综合排序</div>
          <div class="hot" @click="clickActive">
            最热<i
              :class="active && !cur ? 'active' : ''"
              class="iconfont icon-xiangshang"
            ></i
            ><i
              :class="active && cur ? 'active' : ''"
              class="iconfont icon-xiangxia"
            ></i>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="name" label="试卷名称">
            <template slot-scope="scope">
              <el-link
                type="primary"
                :href="`#/Index/Testinfo?id=${scope.row.id}`"
                target="_blank"
                >{{ scope.row.name }}</el-link
              >
            </template>
          </el-table-column>
          <el-table-column prop="difficultys" label="难度">
            <template slot-scope="scope">
              <el-rate
                v-model="scope.row.difficultys"
                :colors="colors"
                disabled
                :size="40"
              >
              </el-rate>
            </template>
          </el-table-column>
          <el-table-column prop="questions_count" label="题数" width="150">
          </el-table-column>
          <el-table-column prop="grade_sum" label="总分" width="150">
          </el-table-column>
          <el-table-column prop="pepole" label="答题人次" width="150">
          </el-table-column>

          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="startTest(scope.row)"
                type="primary"
                size="small"
                >去做题</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageSize.page"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 登录框 -->
    <LoginBox ref="loginBox"></LoginBox>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import LoginBox from "@/components/LoginBox";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    LoginBox,
  },
  head: {
    title: "西知题库 · 永久免费 · 西语人的专属训练营 - 西知题库",
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    return {
      activeName: "全部",
      allList: [], //tab
      allListTotal: 0,
      total: 0,
      tableData: [], //全部数据
      pageSize: {
        limit: 10,
        page: 1,
        srot: "",
        order: "", //records_count
      },
      rowpassword: "", //密码
      centerDialogVisible: false, //做题弹框
      passwordDialogVisible: false, //密码弹框
      rowObj: { name: "全部" }, //tab所在位置

      colors: ["#F7BA2A", "#F7BA2A", "#FF9900"],
      active: false, //综合排序
      cur: false, //最热
      ruleForm: {
        pass: "",
        checkPass: "",
        age: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleSizeChange(val) {
      let _this = this;
      _this.pageSize.limit = val;
      _this.getTestList(_this.rowObj);
    },
    handleCurrentChange(val) {
      let _this = this;
      _this.pageSize.page = val;
      _this.getTestList(_this.rowObj);
    },
    //练习模式
    praticeType(num) {
      let _this = this;
      let obj = {
        id: _this.rowObj.id,
        pattern: num,
      };
      _this.$api.records(obj).then((res) => {
        _this.question = res.data.question_class_record;
        _this.$store.commit("changeCreated", _this.question);
        _this.$store.commit("changeRowObj", _this.rowObj);
        localStorage.name = _this.rowObj.name;
        this.$router.push("/Index/Pratice");
      });
    },
    //考试模式
    testType(num) {
      let _this = this;
      if (_this.rowpassword) {
        _this.$api
          .APIpasswordusercheck({
            question_class_id: _this.rowObj.id,
          })
          .then((res) => {
            if (res.data.question_class_password_user) {
              _this.$message("恭喜您已完成本次专四模拟考试，不可再次答题哦。");
            } else {
              _this.centerDialogVisible = false;
              _this.passwordDialogVisible = true;
            }
          });
      } else {
        let obj = {
          id: _this.rowObj.id,
          pattern: num,
        };
        _this.$api.records(obj).then((res) => {
          _this.question = res.data.question_class_record;
          _this.$store.commit("changeCreated", _this.question);
          _this.$store.commit("changeRowObj", _this.rowObj);
          localStorage.name = _this.rowObj.name;
          _this.$router.push("/Index/Notice");
        });
      }
    },
    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            id: _this.rowObj.id,
            password: _this.ruleForm.pass,
          };

          _this.$api.passwordusercheck(obj).then((res) => {
            _this.passwordDialogVisible = false;
            let obj = {
              id: _this.rowObj.id,
              pattern: 2,
            };
            _this.$api.records(obj).then((res) => {
              _this.question = res.data.question_class_record;
              _this.$store.commit("changeCreated", _this.question);
              _this.$store.commit("changeRowObj", _this.rowObj);
              localStorage.name = _this.rowObj.name;
              _this.$router.push("/Index/Notice");
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    startTest(item) {
      //去做题
      let _this = this;
      let userInfo = localStorage.userInfo; //用户是否登录

      if (userInfo) {
        _this.rowObj = item;
        _this.rowpassword = item.password;
        _this.centerDialogVisible = true; //弹框显示
      } else {
        _this.$refs.loginBox.clickloginDialogBool();
      }
    },
    // handleClick(tab) {
    //   // 切换tab
    //   let _this = this;
    //   _this.active = false;
    //   _this.pageSize.srot = "";
    //   _this.pageSize.order = "";
    //   _this.rowObj = _this.allList[tab.index];
    //   _this.getTestList(_this.allList[tab.index]);
    // },
    tabClick(index) {
      // 切换tab
      let _this = this;
      _this.active = false;
      _this.pageSize.srot = "";
      _this.pageSize.order = "";
      _this.rowObj = _this.allList[index];
      _this.getTestList(_this.allList[index]);
      _this.allList.forEach((v, i) => {
        if (i == index) {
          _this.allList[index].active = true;
        } else {
          v.active = false;
        }
      });
    },
    getTestList(val) {
      // 获取表格
      let _this = this;
      // _this.pageSize.limit = 10;
      // _this.pageSize.page = 1;
      if (val.name == "全部") {
        let obj = {
          limit: _this.pageSize.limit,
          page: _this.pageSize.page,
          order: _this.pageSize.order,
          sort: _this.pageSize.srot,
        };
        _this.$api.classesindexlist(obj).then((res) => {
          _this.total = res.data.lists.total;

          _this.tableData = res.data.lists.data;

          _this.tableData.forEach((v) => {
            v.pepole = v.falsedata + v.records_count;

            localStorage.grade_sum = v.grade_sum;
            v.questions_count = v.questions_count + "大题";
          });
          this.loading.close();
        });
      } else {
        let obj = {
          "filter[parent_id]": val.id,
          limit: _this.pageSize.limit,
          page: _this.pageSize.page,
          order: _this.pageSize.order,
          sort: _this.pageSize.srot,
        };
        _this.$api.classesindexlist(obj).then((res) => {
          _this.total = res.data.lists.total;
          _this.tableData = res.data.lists.data;
          _this.tableData.forEach((v) => {
            v.pepole = v.falsedata + v.records_count;
            v.questions_count = v.questions_count + "大题";
          });
          this.loading.close();
        });
      }
    },
    clickActive() {
      // 最热
      let _this = this;
      _this.active = true;
      if (_this.cur) {
        //小到大
        _this.cur = false;
        _this.pageSize.order = "records_count";
        _this.pageSize.srot = "asc";
        _this.getTestList(_this.rowObj);
      } else {
        // 大到小
        _this.cur = true;
        _this.pageSize.srot = "desc";
        _this.pageSize.order = "records_count";
        _this.getTestList(_this.rowObj);
      }
    },
    clickSort() {
      // 综合排序
      let _this = this;
      _this.active = false;
      _this.cur = false;
      _this.pageSize.srot = "";
      _this.pageSize.order = "";
      _this.getTestList(_this.rowObj);
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
        console.log("移动端页面");
        window.location.href = "http://m.tk.spanishknow.com/#/Testsort";
        // window.location.href = "http://192.168.1.103:3333/#/Testsort";
      } else {
        //跳转pc端页面
        console.log("pc端页面");
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;
    _this.loading = _this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    _this.$api.classes().then((res) => {
      _this.allListTotal = res.data.lists.total;
      _this.allList = res.data.lists.data;

      _this.allList.unshift({ name: "全部" });
      _this.allList.forEach((v, i) => {
        v.active = false;
        if (i == 0) {
          _this.allList[i].active = true;
        }
      });
    });
    // let obj = {
    //   name: "全部",
    // };
    _this.getTestList(_this.rowObj);
    _this.$store.commit("changeurl", window.location.href);
    _this.browserRedirect();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
@vbcolor: #df3833;
//@import url(); 引入公共css类
#Home {
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 1000px;
  /deep/.el-rate__icon {
    margin-right: 0;
  }

  .home-banner {
    width: 100%;
    height: 260px;
    background: url("../../assets/image/home-banner.png") no-repeat;
    background-position: 50%;
    background-size: cover;

    .banner-inner {
      width: 1180px;
      margin: 0 auto;
      color: #fff;
      .banner-title {
        padding-left: 42px;
        font-size: 40px;
        padding-top: 40px;
        line-height: 60px;
      }
      .banner-context {
        margin-top: 12px;
        font-size: 22px;
        line-height: 32px;
        padding-left: 42px;
      }
    }
  }
  .home-content {
    width: 1180px;
    margin: 0 auto;
    padding-top: 30px;
    position: absolute;
    // padding: 15px 20px 20px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    position: relative;
    top: -60px;
    .home_left {
      width: 100%;
      // background: #fff;
      // padding: 20px 30px 49px;
      .list-cut {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        background: #fff;
        font-size: 14px;
        overflow: hidden;
        .iconfont {
          position: absolute;
          right: -24px;
          &:nth-child(1) {
            top: -4px;
          }
          &:nth-child(2) {
            top: 4px;
          }
          &.active {
            color: #df3833;
          }
        }
        .hot {
          position: relative;
        }
        div {
          display: inline-block;
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          color: #575d6c;

          &.total_num {
            color: #252b3a;
          }
          &:nth-child(2) {
            cursor: pointer;
          }
          &:nth-child(3) {
            cursor: pointer;
          }
        }
      }
      .list-title {
        width: 100%;
        margin-bottom: 10px;
        background: #fff;
        font-size: 14px;
        overflow: hidden;
        display: flex;
        padding-top: 20px;
        align-items: flex-start;
        .list_title_left {
          padding-left: 30px;
          // padding-top: 20px;
          font-size: 14px;
          color: #252b3a;
          width: 170px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          li {
            box-sizing: border-box;
            cursor: pointer;
            line-height: 24px;
            list-style: none;
            padding: 0 15px;
            margin: 0 10px;
            margin-bottom: 10px;
            color: #575d6c;
            border: 1px solid #fff;
            &.active {
              border: 1px solid @vbcolor;
              color: @vbcolor;
            }
          }
        }
      }
      /deep/.el-tabs__nav-wrap {
        // border-bottom: 1px solid #dfe1e6;
        font-size: 12px;
        background-color: #fff;
        // height: 54px;
        padding: 20px;
        box-sizing: border-box;

        &::after {
          height: 0px;
        }
        /deep/.is-active {
          color: @vbcolor !important;
          font-size: 18px;
        }
        .el-tabs__nav {
          .el-tabs__active-bar {
            // height: 4px;
            background-color: @vbcolor;
            // width: auto;
            // display: none;
          }

          .el-tabs__item {
            box-sizing: content-box;
            color: #000;
            // font-size: 12px;
            line-height: 24px;
            list-style: none;
            // padding: 0 15px;
            // margin: 15px 10px;
            color: #575d6c;
            position: relative;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            margin-bottom: 0;
          }
        }
      }
      /deep/.el-table__header-wrapper {
        th {
          padding-left: 10px;
          font-size: 14px;
          font-weight: 600;
          border-left: 1px solid #fff;
          color: #252b3a;
          background-color: #e4e7e8;
          &:first-child {
            border-left: none;
          }
        }
      }
      /deep/.el-table__body-wrapper {
        .el-table__row {
          td {
            &:first-child {
              .cell {
                a {
                  color: #1b79c8;
                }
                .el-link.el-link--default:after,
                .el-link.el-link--primary.is-underline:hover:after,
                .el-link.el-link--primary:after {
                  border: none;
                }
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                vertical-align: middle;
                padding-left: 10px;
                text-align: left;
              }
            }
            .cell {
              font-size: 14px;
              color: #252b3a;
            }
            .el-button--primary {
              background-color: @vbcolor;
              border: none;
            }
          }
        }
      }
      /deep/.el-pagination {
        text-align: center;
        padding: 30px;
        color: #575d6c;
        .el-pagination__sizes {
          .el-input__inner {
            &:focus {
              border: 1px solid @vbcolor;
            }
            &:hover {
              border: 1px solid @vbcolor;
            }
          }
        }
        .el-pager {
          li {
            &:hover {
              color: @vbcolor;
            }
            &.active {
              color: @vbcolor;
            }
          }
        }
        .el-pagination__jump {
          .el-input__inner {
            &:focus {
              border: 1px solid @vbcolor;
            }
            &:hover {
              border: 1px solid @vbcolor;
            }
          }
        }
      }
      .click-more {
        cursor: pointer;
        border: 1px solid @vbcolor;
        font-size: 14px;
        color: @vbcolor;
        border-radius: 4px;
        width: 150px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin: 40px auto 0;
      }
    }
    .home_right {
      display: none;
      height: 100%;
      width: 300px;
      .right-img {
        width: 300px;
        height: 156px;
        display: block;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .all-num {
        text-align: center;
        line-height: 12px;
        font-size: 12px;
        color: #666;
        padding: 15px 0;
        background: #fff;
      }
      .right_subConMode {
        height: 90px;
        background: #fff;
        margin: 10px 0;
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          li {
            height: 70px;
            width: 100px;
            vertical-align: bottom;
            position: relative;
            cursor: pointer;

            a {
              display: block;
              text-align: center;
              line-height: 12px;
              font-size: 12px;
              color: #666;
              &:hover {
                color: @vbcolor;
              }

              i {
                width: 25px;
                height: 30px;
                display: block;
                margin: 16px auto 10px;
              }
            }
            &:nth-child(1) {
              i {
                background: url(../../assets/image/4.png) center no-repeat;
                background-size: cover;
              }
            }
            &:nth-child(2) {
              i {
                width: 35px;
                height: 30px;
                background: url(../../assets/image/5.png) center no-repeat;
                background-size: contain;
              }
            }
            &:nth-child(3) {
              i {
                background: url(../../assets/image/6.png) center no-repeat;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      background: url("../../assets/image/index0.png") no-repeat center;
      background-size: 100%;
      .el-dialog__body {
        .testType {
          display: flex;
          justify-content: space-around;
          div {
            text-align: center;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  /deep/.el-table {
    .cell {
      &:first-child {
        padding-left: 10;
      }

      text-align: center;
    }
  }
}
</style>